import React, { useState, useEffect, useContext } from "react";
import { Context } from "redux/Store";
import { actionTypes } from "libs/redux/Reducer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Dry from "./Dry";
import Flaky from "./Flaky";
import Irritated from "./Irritated";

import useDeviceSize from "libs/hooks/useDeviceSize";
import { getQueryStrings } from "../libs/services/utils";

const tabIds = ["dryrough", "flakyfissured", "irritatedexcoriated"];

function BoxNav() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeMainTab, setActiveMainTab] = useState(null);
  const [activeSubTab, setActiveSubTab] = useState(null);
  const { state, dispatch } = useContext(Context);
  const [activeAccordianIndex, setActiveAccordianIndex] = useState(0);

  const deviceSizes = useDeviceSize();

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    dispatch({
      type: actionTypes.SET_ONE_ROOFER_TAB_CLICK,
      payload: index + 1,
    });
  };

  const handleAccordianClick = (uuids) => {
    if (uuids.length > 0) {
      const currentIndex = parseInt(uuids[0]);
      setActiveTabIndex(currentIndex);
      dispatch({
        type: actionTypes.SET_ONE_ROOFER_TAB_CLICK,
        payload: currentIndex + 1,
      });
    }
  };

  useEffect(() => {
    let qstrTab = getQueryStrings()["tab"];
    if (qstrTab) {
      qstrTab && (qstrTab = qstrTab.split("-"));

      setActiveTabIndex(tabIds.indexOf(qstrTab[0]));
      setActiveMainTab(qstrTab[0]);
      setActiveSubTab(qstrTab[1]);
    }
    //console.log("useEffect called");
  }, []);

  useEffect(() => {
    setActiveAccordianIndex(activeTabIndex + 1);
  }, [activeTabIndex]);

  return deviceSizes?.mdUp ? (
    <div className="tabs-main">
      <Tabs
        selectedIndex={activeTabIndex != -1 ? activeTabIndex : 0}
        onSelect={handleTabClick}
      >
        <TabList className="tabs-list">
          <Tab id="#dryrough">DRY | SCALY | ROUGH</Tab>

          <Tab id="#flakyfissured">FLAKY | CRACKED | FISSURED</Tab>

          <Tab id="#irritatedexcoriated">IRRITATED | ITCHY | EXCORIATED</Tab>
        </TabList>

        <TabPanel>
          <Dry
            mainTabId={activeMainTab || "dryrough"}
            subTabId={activeTabIndex == 0 ? activeSubTab : null}
          />
        </TabPanel>

        <TabPanel>
          <Flaky
            mainTabId={activeMainTab || "flakyfissured"}
            subTabId={activeTabIndex == 1 ? activeSubTab : null}
          />
        </TabPanel>

        <TabPanel>
          <Irritated
            mainTabId={activeMainTab || "irritatedexcoriated"}
            subTabId={activeTabIndex == 2 ? activeSubTab : null}
          />
        </TabPanel>
      </Tabs>
    </div>
  ) : (
    <Accordion
      allowZeroExpanded={true}
      //preExpanded={[`${activeTabIndex}`]}
      onChange={(uuids) => handleAccordianClick(uuids)}
    >
      <AccordionItem
        uuid="0"
        dangerouslySetExpanded={activeTabIndex == 0 ? true : false}
      >
        <AccordionItemButton href="#dryrough" id="#dryrough">
          DRY | SCALY | ROUGH
          <div className="arrow"></div>
        </AccordionItemButton>
        <AccordionItemPanel>
          <Dry
            mainTabId={activeMainTab || "dryrough"}
            subTabId={activeTabIndex == 0 ? activeSubTab : null}
          />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem
        uuid="1"
        dangerouslySetExpanded={activeTabIndex == 1 ? true : false}
      >
        <AccordionItemButton href="#flakyfissured" id="#flakyfissured">
          FLAKY | CRACKED | FISSURED
          <div className="arrow"></div>
        </AccordionItemButton>
        <AccordionItemPanel>
          <Flaky
            mainTabId={activeMainTab || "flakyfissured"}
            subTabId={activeTabIndex == 1 ? activeSubTab : null}
          />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem
        uuid="2"
        dangerouslySetExpanded={activeTabIndex == 2 ? true : false}
      >
        <AccordionItemButton
          href="#irritatedexcoriated"
          id="#irritatedexcoriated"
        >
          IRRITATED | ITCHY | EXCORIATED
          <div className="arrow"></div>
        </AccordionItemButton>
        <AccordionItemPanel>
          <Irritated
            mainTabId={activeMainTab || "irritatedexcoriated"}
            subTabId={activeTabIndex == 2 ? activeSubTab : null}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default BoxNav;
