import React, { useState, useContext, useEffect } from "react";
import { Context } from "redux/Store";
import { actionTypes } from "libs/redux/Reducer";
import { Row, Col } from "react-grid-system";

import { CompromisedCout } from "common/callouts";

import step1Img from "images/compromisedskin/flaky-cracked-fissured-step1.webp";
import step1ImgMobile from "images/compromisedskin/flaky-cracked-fissured-step1-mobile.webp";
import step2Img from "images/compromisedskin/dry-skin-cross-section-step2.webp";
import img1 from "images/compromisedskin/aquaphor-healing-ointment.webp";
import img2 from "images/compromisedskin/eucerin-advanced-repair-cream.webp";
//import { changeUrlWithQstr } from "../libs/services/utils";

const StepOne = ({ gotoHandler }) => {
  return (
    <Row align="center" justify="center">
      <Col xs={12}>
        <center>
          <img
            src={step1Img}
            alt="zoomed in pictures of flaky skin"
            className="show-desktop bg-img"
          />
          <img
            src={step1ImgMobile}
            alt="zoomed in pictures of flaky skin"
            className="show-mobile bg-img"
          />
        </center>
      </Col>
      <Col xs={12}>
        <center>
          <a onClick={() => gotoHandler(2)} className="btn btn-start">
            Start &gt;
          </a>
        </center>
      </Col>
    </Row>
  );
};

const StepTwo = ({ gotoHandler }) => {
  return (
    <Row>
      <Col lg={6} xs={12}>
        <div>
          <h1 className="step-2-h1">We can help address skin needs</h1>
          <ul className="arrow-list">
            <li>Help protect from external irritants</li>
            <li>Create an optimal healing environment</li>
            <li>Help heal damaged skin barrier</li>
            <li>Replenish barrier lipids</li>
          </ul>
          <div className="show-desktop">
            <a onClick={() => gotoHandler(3)} className="btn">
              learn about a range of care
            </a>
            <a onClick={() => gotoHandler(1)} className="btn btn-link">
              &lt; Back
            </a>
          </div>
        </div>
      </Col>
      <Col lg={6} xs={12}>
        <center>
          <img
            src={step2Img}
            alt="illustration showing the cross section of dry, crack skin"
          />
          <p className="img-caption">
            Vulnerable to external irritants and increased transepidermal water
            loss
          </p>
          <div className="show-mobile">
            <a onClick={() => gotoHandler(3)} className="btn">
              learn about a range of care
            </a>
            <a onClick={() => gotoHandler(1)} className="btn btn-link">
              &lt; Back
            </a>
          </div>
        </center>
      </Col>
    </Row>
  );
};
const StepThree = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <h1>Help heal. Maintain healthy-looking skin.</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8} lg={6} order={{ xs: 2, lg: 1 }}>
          <CompromisedCout
            head1={`Help heal cracked, fissured skin with Aquaphor Healing Ointment<sup>®</sup>`}
            head1Css="arsenic-gray"
            buttonText="See product details"
            buttonUrl="/body/damagedskin/aquaphordrycrackedskin"
            targetOpen="_blank"
          >
            <ul className="arrow-list">
              <li className="dark-blue">
                <span>Helps protect skin from external irritants</span>
              </li>
              <li className="dark-blue">
                <span>Creates an optimal healing environment</span>
              </li>
              <li className="dark-blue">
                <span>Helps heal a damaged skin barrier</span>
              </li>
            </ul>
          </CompromisedCout>
        </Col>

        <Col xs={12} md={4} lg={6} order={{ xs: 1, lg: 2 }}>
          <img
            src={img1}
            alt="a tub of Aquaphor Healing Ointment® with a red shield graphic behind it"
          />
          <p className="tiny-caption for-flaky">Skin protectant</p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4} lg={6}>
          <img
            src={img2}
            alt="a tub of Eucerin® Advanced Repair Cream with a circle hydration graphic behind it"
          />
        </Col>

        <Col xs={12} md={8} lg={6}>
          <CompromisedCout
            head1={`Maintain skin hydration with Eucerin<sup>®</sup> Advanced Repair Cream`}
            head1Css="arsenic-gray"
            buttonText="See product details"
            buttonUrl="/body/dryskin/eucerinadvancedrepaircream"
            targetOpen="_blank"
            referenceContents="<p class='references'>NMFs=natural moisturizing factors.</p><p class='references'><span class='font-bold'>Reference: 1.</span> Data on file. Beiersdorf Inc.</p>"
          >
            <ul className="arrow-list">
              <li className="blue">
                <span>
                  Replenishes the moisture reservoir with NMFs + ceramide NP
                </span>
              </li>
              <li className="blue">
                <span>
                  Provides superior hydration<sup>1</sup>
                </span>
              </li>
              <li className="blue">
                <span>Suitable for daily moisturization</span>
              </li>
            </ul>
          </CompromisedCout>
        </Col>
      </Row>
    </>
  );
};

function Flaky({ mainTabId, subTabId }) {
  const [currentStep, setCurrentStep] = useState(1);
  const { state, dispatch } = useContext(Context);

  const gotoStep = (step) => {
    setCurrentStep(step);
  };
  useEffect(() => {
    if (subTabId) {
      if (subTabId == "start") {
        setCurrentStep(1);
      }
      if (subTabId == "learn") {
        setCurrentStep(2);
      }
      if (subTabId == "results") {
        setCurrentStep(3);
      }
    }
  }, [subTabId]);

  useEffect(() => {
    if (currentStep == 1) {
      dispatch({
        type: actionTypes.SET_ONE_ROOFER,
        payload: {
          step: "main",
          tab: "flaky",
        },
      });
    }
    if (currentStep == 2) {
      dispatch({
        type: actionTypes.SET_ONE_ROOFER,
        payload: {
          step: "learn",
          tab: "flaky",
        },
      });
    }
    if (currentStep == 3) {
      dispatch({
        type: actionTypes.SET_ONE_ROOFER,
        payload: {
          step: "results",
          tab: "flaky",
        },
      });
    }
  }, [currentStep]);

  useEffect(() => {
    state.oneRooferTabClick && setCurrentStep(1);
  }, [state.oneRooferTabClick]);

  return (
    <div>
      {currentStep == 1 && <StepOne gotoHandler={gotoStep} />}
      {currentStep == 2 && <StepTwo gotoHandler={gotoStep} />}
      {currentStep == 3 && <StepThree />}
    </div>
  );
}

export default Flaky;
