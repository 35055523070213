import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";

import favIcon from "images/favicon.webp";

import "scss/styles.scss";
import "react-responsive-modal/styles.css";

function LayoutAlt({ pageClass, hero, jobCode, children }) {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <link rel="icon" href={favIcon} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Nunito+Sans:ital,wght@0,400;0,700;0,800;1,400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className={`${pageClass ? pageClass : ""} page-container`}>
        <div className="main-content">
          <Header />
          <main>
            {/* {hero && <div className="hero-wrapper">{hero}</div>}
             */}
            <div className="body-content-wrapper">{children}</div>
          </main>
          <Footer jobCode={jobCode} />
        </div>
      </div>
    </>
  );
}

export default LayoutAlt;
