import React, { useState, useContext, useEffect } from "react";
import { Context } from "redux/Store";
import { actionTypes } from "libs/redux/Reducer";
import { Row, Col } from "react-grid-system";

import { CompromisedCout } from "common/callouts";

import step1Img from "images/compromisedskin/irritated-itchy-excoriated-step1.webp";
import step1ImgMobile from "images/compromisedskin/irritated-itchy-excoriated-step1-mobile.webp";
import step2Img from "images/compromisedskin/flaky-skin-cross-section-step2.webp";
import img1 from "images/compromisedskin/eucerin-eczema-relief-cream.webp";
import img2 from "images/compromisedskin/aquaphor-healing-ointment-grp.webp";

const StepOne = ({ gotoHandler }) => {
  return (
    <Row align="center" justify="center">
      <Col xs={12}>
        <center>
          <img
            src={step1Img}
            alt="zoomed in images of irritated skin"
            className="show-desktop bg-img"
          />
          <img
            src={step1ImgMobile}
            alt="zoomed in images of irritated skin"
            className="show-mobile bg-img"
          />
        </center>
      </Col>
      <Col xs={12}>
        <center>
          <a onClick={() => gotoHandler(2)} className="btn btn-start">
            Start &gt;
          </a>
        </center>
      </Col>
    </Row>
  );
};

const StepTwo = ({ gotoHandler }) => {
  return (
    <Row>
      <Col lg={6} xs={12}>
        <div>
          <h1 className="step-2-h1">We can help address skin needs</h1>
          <ul className="arrow-list">
            <li>Provide relief from symptoms of eczema</li>
            <li>
              Help prevent recurrence of eczema flares and prolong time between
              flares
            </li>
            <li>Help protect damaged skin from external irritants</li>
          </ul>
          <div className="show-desktop">
            <a onClick={() => gotoHandler(3)} className="btn">
              see the therapeutic approach
            </a>
            <a onClick={() => gotoHandler(1)} className="btn btn-link">
              &lt; Back
            </a>
          </div>
        </div>
      </Col>
      <Col lg={6} xs={12}>
        <center>
          <img
            src={step2Img}
            alt="illustration of the cross section of itchy, irritated skin"
          />
          <p className="img-caption">
            A compromised stratum corneum with an abnormal pH
          </p>
          <div className="show-mobile">
            <a onClick={() => gotoHandler(3)} className="btn">
              see the therapeutic approach
            </a>
            <a onClick={() => gotoHandler(1)} className="btn btn-link">
              &lt; Back
            </a>
          </div>
        </center>
      </Col>
    </Row>
  );
};
const StepThree = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <h1>
            A therapeutic approach to help protect and fortify a defective skin
            barrier
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8} lg={6} order={{ xs: 2, lg: 1 }}>
          <CompromisedCout
            head1={`Fortify a defective skin barrier with Eucerin<sup>®</sup> Eczema Relief`}
            head1Css="arsenic-gray"
            buttonText="See product details"
            buttonUrl="/body/eczema"
            targetOpen="_blank"
            referenceContents="<p class='references'><span class='font-bold'>Reference: 1.</span> Weber TM, Samarin F, Babcock MJ, Filbry A, Rippke F. Steroid-free over-the-counter eczema skin care formulations reduce risk of flare, prolong time to flare, and reduce eczema symptoms in pediatric subjects with atopic dermatitis. <em>J Drugs Dermatol</em>. 2015;14(5):478-485.</p>"
          >
            <ul className="arrow-list">
              <li className="red">
                <span>
                  Soothe dryness, irritation, and itch associated with eczema
                  with Eucerin<sup>®</sup> Eczema Relief Cream & Body Wash
                </span>
              </li>
              <li className="red">
                <span>
                  Prevent and help prolong time between flares with Eucerin
                  <sup>®</sup> Eczema Relief Cream<sup>1</sup>
                </span>
              </li>
            </ul>
          </CompromisedCout>
        </Col>

        <Col xs={12} md={4} lg={6} order={{ xs: 1, lg: 2 }}>
          <img
            src={img1}
            alt="a tube and a bottle of Eucerin® Eczema Relief cream and cream & body wash"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4} lg={6}>
          <img
            src={img2}
            alt="a tub of Aquaphor Healing Ointment® with a red shield graphic behind it"
          />
          <p className="tiny-caption for-irritate">Skin protectant</p>
        </Col>

        <Col xs={12} md={8} lg={6}>
          <CompromisedCout
            head1={`Help protect a defective skin barrier with Aquaphor Healing Ointment<sup>®</sup>`}
            head1Css="arsenic-gray"
            buttonText="See product details"
            buttonUrl="/body/damagedskin"
            targetOpen="_blank"
          >
            <ul className="arrow-list">
              <li className="dark-blue">
                <span>Helps protect skin from external irritants</span>
              </li>
              <li className="dark-blue">
                <span>Helps heal a damaged skin barrier</span>
              </li>
            </ul>
          </CompromisedCout>
        </Col>
      </Row>
    </>
  );
};

function Irritated({ mainTabId, subTabId }) {
  const [currentStep, setCurrentStep] = useState(1);

  const { state, dispatch } = useContext(Context);
  const gotoStep = (step) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    if (subTabId) {
      if (subTabId == "start") {
        setCurrentStep(1);
      }
      if (subTabId == "seeapproach") {
        setCurrentStep(2);
      }
      if (subTabId == "results") {
        setCurrentStep(3);
      }
    }
  }, [subTabId]);

  useEffect(() => {
    if (currentStep == 1) {
      dispatch({
        type: actionTypes.SET_ONE_ROOFER,
        payload: {
          step: "main",
          tab: "irritate",
        },
      });
    }
    if (currentStep == 2) {
      dispatch({
        type: actionTypes.SET_ONE_ROOFER,
        payload: {
          step: "main",
          tab: "irritate",
        },
      });
    }
    if (currentStep == 3) {
      dispatch({
        type: actionTypes.SET_ONE_ROOFER,
        payload: {
          step: "results",
          tab: "irritate",
        },
      });
    }
  }, [currentStep]);

  useEffect(() => {
    state.oneRooferTabClick && setCurrentStep(1);
  }, [state.oneRooferTabClick]);

  return (
    <div>
      {currentStep == 1 && <StepOne gotoHandler={gotoStep} />}
      {currentStep == 2 && <StepTwo gotoHandler={gotoStep} />}
      {currentStep == 3 && <StepThree />}
    </div>
  );
}

export default Irritated;
