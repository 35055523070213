import React, { useState, useContext } from "react";
import { actionTypes } from "libs/redux/Reducer";
import navSchema from "./NavStructure";
// import { Link } from "gatsby";
import { Context } from "redux/Store";
import svgHamburgerOpen from "images/hamburger-open.svg";
import svgHamburgerClose from "images/hamburger-close.svg";

const getNav = (navList, parent) => {
  let navResult = navList.filter((item) => item.parent === parent);
  return navResult;
};

const getSubNavChildren = (navList, parent) => {
  let navResult = navList.filter((item) => item.parent === parent);
  return navResult.length > 0;
};

function NavMobile() {
  const { state, dispatch } = useContext(Context);

  const [currentMouseClick, setMouseClick] = useState(null);
  const [parentMouseClick, setParentMouseClick] = useState([]);
  const [parentIndex, setParentIndex] = useState(0);
  const [menuDepth, setMenuDepth] = useState(1);

  const closeHamburger = () =>
    dispatch({
      type: actionTypes.SET_HAMBURGER_MENU,
      payload: false,
    });

  const toggleBurger = () =>
    dispatch({
      type: actionTypes.SET_HAMBURGER_MENU,
      payload: !state.hamburgerMenu,
    });

  const handleMouseClick = (item) => {
    setMenuDepth(menuDepth + 1);
    setMouseClick(item);

    const newParentMouseClick = parentMouseClick.filter((parent) => parent);
    setParentMouseClick([...newParentMouseClick, item]);
  };

  const handleMouseClick1 = (item) => {
    setMouseClick(item);
    closeHamburger();
  };

  const handleBack = (id) => {
    setMenuDepth(menuDepth - 1);

    const parentIndex = parentMouseClick.findIndex(
      (item) => item && item.id === id
    );
    const currentIndex = parentIndex === 0 ? parentIndex : parentIndex - 1;

    const current = parentMouseClick[currentIndex];

    if (parentIndex === 0) {
      setMouseClick(null);
    } else {
      setMouseClick(current);
    }

    delete parentMouseClick[parentIndex];
  };

  return (
    <nav className="mobile">
      <button className="btn-hamburger" onClick={() => toggleBurger()}>
        <img
          src={state.hamburgerMenu ? svgHamburgerOpen : svgHamburgerClose}
          alt="mobile menu icon"
        />
      </button>

      {state.hamburgerMenu ? (
        <div className="nav-list">
          <ul className={`level-${menuDepth}`}>
            {/* {currentMouseClick !== null && (
              <li
                className="subnav back"
                onClick={() => handleBack(currentMouseClick?.id)}
              >
                <a>Back</a>
              </li>
            )} */}
            {currentMouseClick && (
              <li
                className="subnav"
                key={currentMouseClick?.id}
                onClick={() => handleMouseClick1(currentMouseClick)}
              >
                <a
                  href={currentMouseClick?.navLink}
                  dangerouslySetInnerHTML={{
                    __html: currentMouseClick?.title,
                  }}
                  activeClassName="active"
                  // partiallyActive={true}
                  target="_blank"
                  rel="noreferrer"
                />
              </li>
            )}
            {getNav(
              navSchema,
              currentMouseClick ? currentMouseClick?.id : null
            ).map((item) =>
              getSubNavChildren(navSchema, item.id) ? (
                <li
                  className="subnav hasdropdown"
                  key={item.id}
                  onClick={() => handleMouseClick(item)}
                >
                  <a
                    href={
                      currentMouseClick?.parent === item.parent
                        ? item.navLink
                        : item.navLink
                    }
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    activeClassName="active"
                    // partiallyActive={true}
                    target="_blank"
                    rel="noreferrer"
                  />
                </li>
              ) : (
                <li
                  className="subnav"
                  key={item.id}
                  onClick={() => handleMouseClick1(item)}
                >
                  <a
                    href={item.navLink}
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    activeClassName="active"
                    // partiallyActive={true}
                    target="_blank"
                    rel="noreferrer"
                  />
                </li>
              )
            )}

            <a
              className="button"
              href="https://bdf.hcpsampling.com"
              target="_blank"
              rel="noreferrer"
            >
              Request Samples
            </a>

            <a
              className="button"
              href="/sign-up"
              style={{ marginTop: 25 }}
              target="_blank"
              rel="noreferrer"
            >
              SIGN UP FOR EMAILS
            </a>
          </ul>
        </div>
      ) : null}
    </nav>
  );
}

export default NavMobile;
