import React, { useContext } from "react";
import { Context } from "redux/Store";
import { Link } from "gatsby";
import { actionTypes } from "libs/redux/Reducer";
import { Row, Col } from "react-grid-system";
import logoEucerin from "images/eucerin-logo.webp";
import logoAquaphor from "images/aquaphor-logo.webp";
import logoBeirsdorf from "images/beirsdorf-logo.webp";

const LinksBlock = ({ className }) => (
  <div className={`links-block ${className || ""}`}>
    <ul>
      <li>
        <Link to="/aboutus">About Us</Link>
      </li>
      <li>
        <Link to="/termsandconditions">Terms and Conditions</Link>
      </li>
      <li>
        <Link to="/privacypolicy">Privacy Policy</Link>
      </li>
      <li>
        <a
          href="https://www.eucerinus.com/meta-pages/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </li>
      <li>
        <Link to="/sitemap">Site Map</Link>
      </li>
    </ul>
  </div>
);

function Footer({ jobCode }) {
  const { state, dispatch } = useContext(Context);
  const closeHamburger = () =>
    dispatch({
      type: actionTypes.SET_HAMBURGER_MENU,
      payload: false,
    });
  return (
    <footer>
      <div className="inner-centered-container">
        <div className="logo-block">
          <Row align="center" justify="center" className="no-mx">
            <Col sm={12} md={4} id="logos-block">
              <div className="logos">
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={logoEucerin}
                    alt="Eucerin Logo"
                    className="logo-eucerin"
                  />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={logoAquaphor}
                    alt="Aquaphor Logo"
                    className="logo-aquaphor"
                  />
                </a>
              </div>
            </Col>
            <Col sm={12} md={5} id="copyright-text-block">
              <p className="copyright-text">&copy; Beiersdorf 2023</p>
            </Col>
            <Col sm={12} md={3} id="beirsdorf-logo-div">
              <div className="beirsdorf-logo">
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={logoBeirsdorf}
                    alt="Beiersdorf Logo"
                    className="logo-beirsdorf"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
