import React, { useState } from "react";
import navSchema from "./NavStructure";
import { Link } from "gatsby";
/**
 * Desktop Menu
 * @param {*} navList
 * @param {*} parent
 * @returns
 */

const getNav = (navList, parent) => {
  let navResult = navList.filter((item) => item.parent === parent);
  return navResult;
};

const getSubNavChildren = (navList, parent) => {
  let navResult = navList.filter((item) => item.parent === parent);
  return navResult.length > 0 ? navResult : null;
};

function Nav() {
  const [currentHover, setCurrenHover] = useState(null);
  let menuTimeout = null;

  const hideSubmenu = () => setCurrenHover(null);
  const dontHideSubmenu = () => clearTimeout(menuTimeout);
  const handleMouseEnter = (id) => {
    setCurrenHover(id);
    clearTimeout(menuTimeout);
  };

  const handleMouseOut = () => {
    menuTimeout = setTimeout(() => hideSubmenu(), 100);
  };

  return (
    <>
      <nav className="desktop">
        {
          <ul className="level-1">
            {getNav(navSchema, null).map((item) => (
              <li key={item.id}>
                <a
                  href={item.navLink}
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={() => handleMouseOut()}
                  activeClassName="active"
                  partiallyActive={true}
                  target="_blank"
                  rel="noreferrer"
                />
              </li>
            ))}
          </ul>
        }
      </nav>
      {currentHover && (
        <div
          className="desktop sub-nav-container"
          onMouseEnter={() => dontHideSubmenu()}
          onMouseLeave={() => hideSubmenu()}
        >
          <SubNavigation _itemId={currentHover} />
        </div>
      )}
    </>
  );
}

export default Nav;

function SubNavigation({ _itemId }) {
  return getSubNavChildren(navSchema, _itemId) ? (
    <ul className="level-2">
      {getSubNavChildren(navSchema, _itemId).map((item) => (
        <li
          key={item.id}
          className={`sub-nav ${
            getSubNavChildren(navSchema, item.id) ? "add-border-bottom" : ""
          }`}
        >
          <Link
            to={item.navLink}
            dangerouslySetInnerHTML={{
              __html: item.title,
            }}
            activeClassName="active"
            partiallyActive={true}
          />

          {getSubNavChildren(navSchema, item.id) && (
            <ul className="level-3">
              {getSubNavChildren(navSchema, item.id).map((item) => (
                <li key={item.id}>
                  <Link
                    to={item.navLink}
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    activeClassName="active"
                    partiallyActive={true}
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  ) : null;
}
