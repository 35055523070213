import React from "react";
import { Visible } from "react-grid-system";
import useDeviceSize from "libs/hooks/useDeviceSize";

function HeroAlt({ bgImagePath, className, children, ...props }) {
  const deviceSizes = useDeviceSize();
  return (
    <section
      {...props}
      style={{ backgroundImage: `url(${bgImagePath})` }}
      className={`hero ${className ? className : ""} ${
        deviceSizes?.lgDown ? "hero-small-screen" : ""
      }`}
    >
      {children && (
        <div className="inner-centered-container hero-content-container">
          <Visible xs sm md lg>
            <img src={bgImagePath} alt="" className="hero-bg-small" />
          </Visible>
          {children}
        </div>
      )}
    </section>
  );
}

export default HeroAlt;
