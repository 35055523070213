import React, { useContext } from "react";
import { Context } from "redux/Store";
import LayoutAlt from "../../components/layout-alt";
import HeroAlt from "../../components/layout-alt/Hero";
//import Layout from "layout";
import Hero from "common/hero";
import Seo from "common/seo";
import BoxNav from "../../components/compromised/Nav";
import { Row, Col } from "react-grid-system";
import { TypeA } from "common/callouts";
import heroImageRef from "images/compromisedskin/hero.webp";
// import heroImageRefMob from "images/compromisedskin/hero-mobile.webp";
import heroImageRefMob from "images/compromisedskin/hero.webp";
import heroImageRefDryResults from "images/compromisedskin/hero-dry.webp";
import heroImageRefDryResultsMob from "images/compromisedskin/hero-dry-mobile.webp";
import heroImageRefFlakyResults from "images/compromisedskin/hero-flaky.webp";
import heroImageRefFlakyResultsMob from "images/compromisedskin/hero-flaky-mobile.webp";
import heroImageRefIrritateResults from "images/compromisedskin/hero-irritated.webp";
import heroImageRefIrritateResultsMob from "images/compromisedskin/hero-irritated-mobile.webp";
import useDeviceSize from "libs/hooks/useDeviceSize";
import img1 from "images/compromisedskin/cta-img1.webp";
import img2 from "images/compromisedskin/cta-img2.webp";
import img3 from "images/compromisedskin/cta-img3.webp";
import img4 from "images/compromisedskin/cta-img4.png";
import img5 from "images/compromisedskin/cta-img5.png";
import img6 from "images/compromisedskin/cta-img6.png";

import "./index.scss";

const CompromisedskinPage = ({ pageContext }) => {
  const { state, dispatch } = useContext(Context);
  const deviceSizes = useDeviceSize();
  return (
    <LayoutAlt
      pageClass="compromisedskin-page"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Compromisedskin"
        pageDescription="Dry, compromised skin needs comprehensive care"
      />

      {!(state.oneRoofer?.currentStep === "results") && (
        <div className="hero-wrapper">
          <HeroAlt
            className="inner-hero"
            bgImagePath={deviceSizes?.lgDown ? heroImageRefMob : heroImageRef}
            alt="Product shot or Eucerin products and Aquaphor Healing ointment"
          >
            <div className="inner-hero-content">
              <h1>
                Dry, compromised skin
                {deviceSizes?.lgDown ? <>&nbsp;</> : <br />}
                needs <nobr>comprehensive care</nobr>
              </h1>
              <p className="hero-reg-text">
                From skin care to skin repair, rely on advanced
                {deviceSizes?.lgDown ? <>&nbsp;</> : <br />}moisturizing
                formulations that help meet
                {deviceSizes?.lgDown ? <>&nbsp;</> : <br />}your{" "}
                <nobr>therapeutic goals.</nobr>
              </p>
            </div>
          </HeroAlt>
        </div>
      )}
      <div className="hero-wrapper result-step">
        {state.oneRoofer?.currentTab == "dry" &&
          state.oneRoofer?.currentStep == "results" && (
            <Hero
              className="inner-hero dry-results"
              bgImagePath={
                deviceSizes?.mdDown
                  ? heroImageRefDryResultsMob
                  : heroImageRefDryResults
              }
              alt="hands touching that have dry skin symptoms "
            ></Hero>
          )}
        {state.oneRoofer?.currentTab == "flaky" &&
          state.oneRoofer?.currentStep == "results" && (
            <Hero
              className="inner-hero flaky-results"
              // bgImagePath={heroImageRefFlakyResults}
              bgImagePath={
                deviceSizes?.mdDown
                  ? heroImageRefFlakyResultsMob
                  : heroImageRefFlakyResults
              }
              alt="cracked heal image"
            ></Hero>
          )}
        {state.oneRoofer?.currentTab == "irritate" &&
          state.oneRoofer?.currentStep == "results" && (
            <Hero
              className="inner-hero irritated-results"
              //bgImagePath={heroImageRefIrritateResults}
              bgImagePath={
                deviceSizes?.mdDown
                  ? heroImageRefIrritateResultsMob
                  : heroImageRefIrritateResults
              }
              alt="image of an elbow fold with eczema"
            ></Hero>
          )}
      </div>
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <h2>
            Select a skin concern and see how we <nobr>can help</nobr>
          </h2>
        </section>
      </div>
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <BoxNav />
        </section>
      </div>
      {state.oneRoofer?.currentTab == "dry" &&
        state.oneRoofer?.currentStep == "results" && (
          <section className="callouts-container gradient-bg blue-grad last">
            <div className="inner-centered-container">
              <Row>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img1}
                    imgAlt="a thumbnail image of a downloading patient resource about Aquaphor®"
                    caption="Help patients combat everyday<br class='show-desktop' /> skin concerns with the many<br class='show-desktop' /> uses <nobr>of Aquaphor</nobr>"
                    buttonText="download now"
                    buttonUrl="https://cdn.sanity.io/files/94ssx197/production/13ed4610896782864c0d5a42cd71a74356aa4d86.pdf"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img2}
                    imgAlt="a thumbnail image of a video about skin hydration"
                    caption="Learn how improved<br class='show-desktop' /> skin hydration can help<br class='show-desktop' /> manage xerosis"
                    buttonText="watch video"
                    buttonUrl="/resources/video/?wistia_id=a6w1a4iyiy"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img3}
                    imgAlt="a thumbnail image of a laptop with an image of the resource suite"
                    caption="Sign up to become a<br class='show-desktop' /> Beiersdorf Insider"
                    buttonText="GO NOW"
                    buttonUrl="/sign-up"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
              </Row>
            </div>
          </section>
        )}
      {state.oneRoofer?.currentTab == "flaky" &&
        state.oneRoofer?.currentStep == "results" && (
          <section className="callouts-container gradient-bg blue-grad last">
            <div className="inner-centered-container">
              <Row>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img4}
                    imgAlt="a thumbnail image of an Aquaphor flashcard"
                    caption="A head-to-head comparison of<br class='show-desktop' /> minor wound-healing ability"
                    buttonText="download now"
                    buttonUrl="https://cdn.sanity.io/files/94ssx197/production/45c9a551063fcb76a3a61eb77c40263d76bc0655.pdf"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img5}
                    imgAlt="a thumbnail image of an Aquaphor flashcard"
                    caption="Review the proprietary purification<br class='show-desktop' /> process developed by the skin care<br class='show-desktop' /> experts <nobr>at Beiersdorf</nobr>"
                    buttonText="download now"
                    buttonUrl="https://cdn.sanity.io/files/94ssx197/production/fcfed702d7330ecc79cdf0c4dac6005dd35ef608.pdf"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img3}
                    imgAlt="a thumbnail image of a laptop with an image of the resource suite"
                    caption="Sign up to become a<br class='show-desktop' /> Beiersdorf Insider"
                    buttonText="GO NOW"
                    buttonUrl="/sign-up"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
              </Row>
            </div>
          </section>
        )}
      {state.oneRoofer?.currentTab == "irritate" &&
        state.oneRoofer?.currentStep == "results" && (
          <section className="callouts-container gradient-bg blue-grad last">
            <div className="inner-centered-container">
              <Row>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img6}
                    imgAlt="thumbnail image of an article about flare prevention"
                    caption="Explore a clinical study of flare<br class='show-desktop' /> prevention for pediatric subjects<br class='show-desktop' /> with atopic dermatitis"
                    buttonText="download now"
                    buttonUrl="https://cdn.sanity.io/files/94ssx197/production/2594d64f14e22c0f9b38d2d76a3934bed313332d.pdf"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img4}
                    imgAlt="a thumbnail image of an Aquaphor flashcard"
                    caption="A head-to-head comparison of<br class='show-desktop' /> minor wound-healing ability"
                    buttonText="download now"
                    buttonUrl="https://cdn.sanity.io/files/94ssx197/production/45c9a551063fcb76a3a61eb77c40263d76bc0655.pdf"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
                <Col
                  sm={12}
                  md={4}
                  className={deviceSizes?.smUp ? "no-pl" : ""}
                >
                  <TypeA
                    imgRef={img3}
                    imgAlt="a thumbnail image of a laptop with an image of the resource suite"
                    caption="Sign up to become a<br class='show-desktop' /> Beiersdorf Insider"
                    buttonText="GO NOW"
                    buttonUrl="/sign-up"
                    imgStyles={{ height: 185 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
              </Row>
            </div>
          </section>
        )}
    </LayoutAlt>
  );
};
export default CompromisedskinPage;
