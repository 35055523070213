import React, { useContext, useRef, useState } from "react";
import Nav from "./Nav";
import NavMobile from "./NavMobile";
import { Context } from "redux/Store";
import { actionTypes } from "libs/redux/Reducer";
import logoEucerin from "images/eucerin-logo.webp";
import logoAquaphor from "images/aquaphor-logo.webp";

function Header() {
  const { state, dispatch } = useContext(Context);
  const [searchOpened, setOpened] = useState(false);
  const searchRef = useRef(null);

  const closeHamburger = () =>
    dispatch({
      type: actionTypes.SET_HAMBURGER_MENU,
      payload: false,
    });
  return (
    <header>
      <div className="utility-bar-bg">
        <div className="inner-centered-container">
          <div className="utility-bar">
            <span>For Healthcare Professionals</span>
            <span>
              <a
                href="https://bdf.hcpsampling.com"
                className="button show-desktop-nav"
                target="_blank"
                rel="noreferrer"
              >
                REQUEST SAMPLES
              </a>
              <a
                href="/sign-up"
                className="button button_dark show-desktop-nav"
                style={{ cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                SIGN UP FOR EMAILS
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="logo-nav-bar-bg">
        <div className="inner-centered-container">
          <div className="logo-nav-bar">
            <div className="logos">
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeHamburger}
              >
                <img
                  src={logoEucerin}
                  alt="Eucerin Logo"
                  className="logo-eucerin"
                />
              </a>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeHamburger}
              >
                <img
                  src={logoAquaphor}
                  alt="Aquaphor Logo"
                  className="logo-aquaphor"
                />
              </a>
            </div>
            <div className="show-desktop-nav">
              <Nav />
            </div>
            <div className="hide-mobile-nav">
              <NavMobile />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
